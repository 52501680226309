<template>
  <v-container>
    <v-list subheader two-line class="mt-1">
      <v-list-item>
        <v-list-item-avatar rounded>
          <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>I'm a Cashier</v-list-item-subtitle>
          <v-list-item-title>{{ currentUser.displayName }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-badge bordered overlap color="red" dot>
            <v-icon>fa-bell</v-icon>
          </v-badge>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <strong class="ml-3">Mes articles</strong>
    <v-list subheader two-line class="mt-1" >
      <v-list-item v-for="item in this.$store.state.cart" :key="item.id">
        <v-list-item-avatar rounded color="grey lighten-4">
          <v-img src="2.png"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2">
            <router-link :to="{name: 'productDetail', params: {id : item.id}}">
              {{ item[0].name }}
            </router-link>
          </v-list-item-title>
          <v-list-item-subtitle>x{{ item[1] }}
            <v-btn plain color="#704232" small @click="$store.commit('removeFromCart', item)">Supprimer
              <v-icon right>mdi-close-circle-outline</v-icon>
            </v-btn>

          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="caption">{{ item[0].price * item[1] }} €</v-list-item-action>
      </v-list-item>
    </v-list>
    <v-divider class="mx-4"></v-divider>
    <v-toolbar color="rgba(0,0,0,0)" flat >
      <strong>SubTotal</strong><v-spacer></v-spacer><strong>{{ calculTotal }}</strong>
    </v-toolbar>
    <v-toolbar color="rgba(0,0,0,0)" flat >
      <strong>TVA</strong><v-spacer></v-spacer><strong>{{ tax }}</strong>
    </v-toolbar>
    <v-toolbar color="rgba(0,0,0,0)" flat >
      <strong>Total TTC</strong><v-spacer></v-spacer><strong>{{ grandTotal }}</strong>
    </v-toolbar>

    <div class="mx-3 mt-2">
      <v-btn color="#14ad95" block dark class="widthoutupercase" @click="addCommande($store.state.cart)">Confirmer Commande</v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {db, firebase} from "../main";

export default {
  data(){
    return{
      productTotal:0,
      total:0,
      orders:[],
      order:{
        number: '',
        date: '',
        total: 0,
      },

    }
  },
  created() {
    this.calculTotal()
  },
  computed : {
    ...mapGetters(["currentUser", "cart"]),
    calculTotal: function (){
      let total = 0
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        total += this.$store.state.cart[i][0].price*this.$store.state.cart[i][1]
      }
      return total
    },
    calculSubTotal:function(){
      var s=0;
      // this.items.forEach(function(item){
      //   if(item.checked){
      //     s+=item.price*item.quantity;
      //   }
      // });
      return s;
    },
    tax:function(tax){
      return this.subTotal*tax;
    },
    grandTotal:function(){
      return this.subTotal;
    }
  },
  methods : {
    createOrder(cart){
      this.$store.dispatch("getOrder", cart)
    },
    async addCommande(cart) {
      let orderlines = []
      for (let i = 0; i < cart.length; i++) {
        orderlines.push(cart[i][0])
      }
      await this.$store.dispatch("getOrder", cart)
      await db.collection("commandes").add(
          {
            number: "",
            date: firebase.firestore.FieldValue.serverTimestamp(),
            products: orderlines,
            user: this.currentUser.uid,
            total: this.calculTotal,
            status: "Envoyée"
          }
      )
          .then(function (orderRef) {
            console.log("Document written with ID: ", orderRef.id);
            db.collection("commandes").doc(orderRef.id).update({
              number: orderRef.id
            }).then(() => {
              console.log("Document successfully updated!");
            })
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          })
    },
    randomString(len) {
      var str = "";                                // String result
      for (var i = 0; i < len; i++) {              // Loop `len` times
        var rand = Math.floor(Math.random() * 62); // random: 0..61
        var charCode = rand += rand > 9 ? (rand < 36 ? 55 : 61) : 48; // Get correct charCode
        str += String.fromCharCode(charCode);      // add Character to str
      }
      return str; // After all loops are done, return the concatenated string
    }
  }
}
</script>

<style>
.v-card.borderme{
  border:2px solid #704232 !important;
}
.col-12 {
  padding: 5px !important;
}
.v-btn.widthoutupercase{
  text-transform: none !important;
}
.greg {
  background-color: #de4b29;
}
</style>